/**
 * Created on : 2018
 * Author     : WebDesign
 */

/* === FOOTER === */

#footer {
  padding-top: 50px;
}
#footer small {
  color: #2cada0;
  display: inline-block;
  width: 100%;
  text-align: center;
  text-transform: uppercase;
}
#footer .logo-n-credits, #footer .areas,
#footer .certy-n-devlogo,
#footer ul, #footer ul li {
  margin: 0;
  padding: 0;
}
#footer ul {
  list-style-type: none;
}
#footer ul li {
  display: inline-block;
}
#footer .logo-n-credits {
  position: relative;
  display: block;
  padding-left: 0; /* 8.33% */
}
#footer .logo-n-credits small {
  position: absolute;
  bottom: 60px;
  left: 0;
  text-align: center;
  /*margin-left: 25%;*/
}
#footer .logo-n-credits small.year {
  bottom: 40px;
}
#footer .areas { }
#footer .areas img {
  display: block;
  margin: 0;
  margin: 0 auto;
  paffing: 0;
}
#footer .areas small {
  display: flex;
  word-spacing: 100vw;
}
#footer .areas ul {
  text-align: center;
}
#footer .areas ul li {
  width: 32%;
  text-align: center;
}
#footer .areas img {
  width: 80px;
  height: auto;
}
#footer .certy-n-devlogo {
  display: block;
  position: relative;
  padding-right: 0; /* 8.33%; */
  text-align: center;
}
#footer .certy-n-devlogo ul {
  text-align: center;
}
#footer .certy-n-devlogo .devlogo {
  position: absolute;
  bottom: 40px;
  right: 0;
  transform: translate3d(50%, 50%,0);
  margin: 0;
  margin-right: 50%;
}

/* === UP&BACK  === */

.my-go-prev, .my-go-bg, .my-go-prev > span {
  height: 40px;
  width: 40px;
}
.my-go-prev, .my-go-up {
  background: transparent url('/img/frontend_assets/navigation_arrow_green.png') no-repeat center;
  position: absolute;
  z-index: 999;
  bottom: 250px;
  cursor: pointer;

  /* -------------- */
}

.my-go-prev {
  display:none;
  bottom:11%;
  transform: rotate(180deg);
  /*background: red;*/
  left: 2%;
  /*bottom: 0;*/
  position: fixed;
}
.my-go-up {
  display:none;
  bottom: 11%;
  transform: rotate(270deg);
  /*background: green;*/
  right: 2%;
  /*bottom: 0;*/
  position: fixed;
}

@media screen and (min-width: 320px) {
  /*    #footer{
          padding-top: 100px;
      }*/

  #footer .certy-n-devlogo .devlogo {
    position: absolute;
    bottom: 40px;
    right: 0;
    transform: translate3d(50%, 50%,0);
    margin: 0;
    margin-right: 50%;
  }
}

@media screen and (min-width: 576px) {
  #footer .certy-n-devlogo .devlogo {
    bottom: 50px;
    margin-right: 50px;
    transform: none;
  }
}

@media screen and (min-width: 1200px) {
  #footer .logo-n-credits {
    padding-left: 8.33%;
  }
  #footer .logo-n-credits small {
    text-align: left;
    margin-left: 25%;
  }
  #footer .certy-n-devlogo ul {
    text-align: right;
  }
  #footer .certy-n-devlogo {
    padding-right: 8.33%;
  }
  #footer .certy-n-devlogo .devlogo {
    margin-right: 25%;
  }
}
